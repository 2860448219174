import { useAuthTokens } from "api/auth";
import classNames from "classnames";
import Button from "components/Button";
import { ChangelogModalStateContext } from "components/Changelog";
import { UserAvatar, UserAvatarv2 } from "components/User";
import { User, Userv2 } from "models/user";
import Image from "next/image";
import Link from "next/link";
import { Router, useRouter } from "next/router";
import { FocusEventHandler, Ref, useContext, useEffect, useRef, useState } from "react";


export function UserMenuButton({ icon, name, onClick, link }: UserButtonProps) {
  const cx = classNames('flex', 'flex-row', 'gap-0.5', 'items-center', 'justify-start');
  const element = <>
    {icon && <img src={icon} width="20px" height="20px" className="h-1 w-auto" />}
    <span className="text-black whitespace-nowrap font-medium inline">{name}</span>
  </>;
  return onClick ? <button className={classNames('bg-transparent', 'h-fit', 'w-fit', cx)} onClick={onClick}>{element}</button> : <Link href={link ?? '#'} passHref>
    <a className={classNames('opacity-1', 'no-default-styles', cx)}>{element}</a>
  </Link>;
}

export type UserButtonProps = {
  icon?: string
  name: string
  link?: string
  onClick?: () => any
}

export function UserMenu({ name, nickname, journal_id, absolute, onBlur, ref }: Omit<Partial<Userv2> & Required<Pick<Userv2, 'nickname'>>, 'avatarUrl'> & {
  name: string
  absolute?: boolean,
  onBlur?: FocusEventHandler<HTMLDivElement>,
  ref?: Ref<HTMLDivElement>
}) {
  const router = useRouter()
  const [, setTokens] = useAuthTokens()
  const [, setChangelogOpen] = useContext(ChangelogModalStateContext)
  return <div className={classNames(
    'bg-white',
    'p-2',
    'rounded-2xl',
    'flex',
    'gap-1',
    'flex-col',
    'z-50',
    {
      'absolute mt-0.5 right-0 border-[1px] border-light-grey shadow-lg': absolute,
    }
  )} onBlur={onBlur} ref={ref}>
    <div className="flex gap-0 2 flex-col items-center">
      <h2 className="text-lg font-bold whitespace-nowrap text-black">{name}</h2>
      <h3 className="text-md font-medium whitespace-nowrap text-grey">{nickname}</h3>
    </div>
    <hr className="h-[0.5px] border-light-grey" />
    <div className="flex flex-col w-full gap-0.5">

      {/* <UserMenuButton icon="/images/navbar/profile.svg" name="Профиль" link="/profile/@me" /> */} {/* TODO: @me */}
      <UserMenuButton icon="/images/navbar/profile.svg" name="Профиль" link={`/profile/@me`} />
      {!!journal_id && <UserMenuButton icon="/images/navbar/journal.svg" name="Журнал" link={`/journals/@me`} />}
      <UserMenuButton icon="/images/navbar/settings.svg" name="Настройки" link={`/settings`} />
      <UserMenuButton icon="/images/navbar/changelog.svg" name="Что нового" onClick={() => setChangelogOpen(true)} />
      <UserMenuButton icon="/images/navbar/logout.svg" name="Выйти" onClick={() => {
        setTokens(null)
      }} />
    </div>
  </div>
}

export default function NavbarUser({ user }: { user: Userv2 }) {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const handleOutsideClick = (e: MouseEvent) => {
    if (!ref.current?.contains(e.target as Node)) setOpen(false)
  }
  const router = useRouter();

  useEffect(() => {
    if (open) document.addEventListener('click', handleOutsideClick)
    else document.removeEventListener('click', handleOutsideClick)
  }, [open])


  return <div className="relative" ref={ref}>
    <button
      className="bg-transparent block"
      onClick={() => {
        setOpen(!open)
      }}
    >
      <UserAvatar height={50} width={50} nickname={`${user.first_name} ${user.second_name}`} className="h-2 w-auto" />
    </button>
    {open && <UserMenu name={`${user!.first_name} ${user!.second_name}`} nickname={user!.nickname!} journal_id={user.journal_id} onBlur={() => setOpen(false)} absolute />}
  </div>
}
